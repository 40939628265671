<template>
    <div class="col-4 general_info__container">
        <div class="row no-gutters general_info__title">
            GENERAL INFO
        </div>
        <div class="row no-gutters general_info__row">
            <div class="col-6 general_info__row__key">
                Partner Assignment
            </div>
            <div class="col-6 general_info__row__value">
                {{ partner.partnerAssignment }}
            </div>
        </div>
        <div class="row no-gutters general_info__row">
            <div class="col-6 general_info__row__key">
                Partner Shortcode
            </div>
            <div class="col-6 general_info__row__value">
                {{ partner.partnerShortCode }}
            </div>
        </div>
        <div class="row no-gutters general_info__row">
            <div class="col-6 general_info__row__key">
                Company Number
            </div>
            <div class="col-6 general_info__row__value">
                {{ partner.companyNumber }}
            </div>
        </div>
        <div class="row no-gutters general_info__row">
            <div class="col-6 general_info__row__key">
                VAT # (optional)
            </div>
            <div class="col-6 general_info__row__value">
                {{ partner.vat }}
            </div>
        </div>
        <div class="row no-gutters general_info__row">
            <div class="col-6 general_info__row__key">
                Legal Address
            </div>
            <div class="col-6 general_info__row__value">
                {{ fullAddress }}
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: 'PartnerGeneralInfo',
    props: {
        partner: {
            type: Object,
            required: true,
        },
    },
    computed: {
        fullAddress() {
            return `${this.partner.addressLine1} \n ${this.partner.addressLine2}`
        }
    },
}
</script>

<style lang="scss" scoped>

.general_info {

    &__title {
        padding-top: 22px;
        padding-left: 34px;
        font-size: 12px;
        line-height: 14px;

    }

    &__row {
        padding-top: 12px;
        padding-left: 34px;
        color: #555555;	
        font-family: Roboto;
        font-size: 14px;	
        line-height: 16px;   

        &__key {
            font-weight: bold;	

        }

        &__value {

        }
    }
}

</style>
