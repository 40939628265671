<template>
    <div class="col-3 contact_info__container">
        <div class="row no-gutters contact_info__title">
            {{ title }}
        </div>
        <div class="row no-gutters contact_info__value name">
            {{ contactName }}
        </div>  
        <div class="row no-gutters contact_info__value email">
            {{ contactEmail }}
        </div>
        <div class="row no-gutters contact_info__value phone">
            {{ contactPhone }}
        </div>
    </div>

</template>

<script>
export default {
    name: 'PartnerContactInfo',
    props: {
        title: {
            type: String,
            required: true,
        },
        contactName: {
            type: String,
            required: true,
        },
        contactEmail: {
            type: String,
            required: true,
        },
        contactPhone: {
            type: String,
            required: true,
        }

    }
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';

.contact_info {
    &__container {

    }

    &__title {
        padding-top: 22px;
        font-size: 12px;
        line-height: 14px;

    }

    &__value {
        padding-top: 12px;
        color: #555555;	
        font-family: Roboto;
        font-size: 14px;	
        line-height: 16px;   

        &__name {
            font-weight: bold;	
        }

        &__email {

        }
    }
}

.name {
    font-weight: bold;
}

.email {
    color: $brand_color;
}

</style>
