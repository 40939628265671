<template>
    <div>
        <ContextTitle
            :passedActions="titleBarActions" 
            title="Partners"
        />

        <PartnersAddRow
            v-if="canEdit"
            logo="partner"
            @click.native="createPartner"
        >
            Add new partner
        </PartnersAddRow>

        <template 
            v-for="partner in partnerList"
        >
            <PartnerRow
                :key="partner.id"
                :partner="partner"
                :active="activeRows.includes(partner.id)"
                @deletePartner="deletePartner"
            />
        </template>

    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import bus from '../../store/action_bus.js'
import Vue from 'vue'

import ContextTitle from '../../components/context_title.vue'
import PartnersAddRow from 'components/partners/partners_add_row.vue'
import PartnerRow from 'components/partners/partner_row.vue'

export default {
    name: 'PartnerList',
    components: {
        ContextTitle,
        PartnersAddRow,
        PartnerRow,
    },
    data() {
        return {
            activeRows: [],
        }
    }, 
    computed: {
        titleBarActions() {
            const expand = {
                type: 'expand',
                run: this.expandAll,
            }

            const collapse = {
                type: 'collapse',
                run: this.collapseAll,
            }
            
            return [expand, collapse]
        },
        ...mapGetters('PartnerStore', [
            'partnerList',
            'canEdit'
        ])
    },
    mounted() {
        this.loadAllPartnerInformation() 
    },
    methods: {
        expandAll() {
            this.activeRows = this.partnerList.map(el => el.id)
        },
        collapseAll() {
            this.activeRows = []
        },
        deletePartner(partnerId) {
            this.deletePartner(partnerId)
        },
        ...mapActions('PartnerStore', [
            'loadAllPartnerInformation',
            'deletePartner'
        ]),
        createPartner() {
            this.$router.push({name: 'partnerCreate'})
        }
    },
}
</script>

<style lang="scss">
</style>
