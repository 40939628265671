<template>
    <div
        class="partner_row"
        :class="{ 'partner_row--active': active}"
    >
        <LineItem class="height-xs--7">
            <div 
                class="partner_row__line row no-gutters height-xs--inherit align-items-center"
                @click="toggle"
            >
                <div class="partner_row__head col-4">
                    <div 
                        class="partner_row__icon" 
                        :class="{'partner_row__icon--active': active}"
                    />

                    <div class="partner_row__title">{{ partner.partnerName }}</div>
                </div>

                <div class="partner_row__head col-2">
                    <div class="partner_row">{{ partner.country + '_' + partner.partnerShortCode }}</div>
                </div>

            </div>
        </LineItem>

        <div
            class="partner_row__expanded row no-gutters"
            :style="{maxHeight: active ? activeHeight : 0}"
        >
            <LineItem
                class="col-12 row no-gutters partner_row__expanded__info" 
            >
                <PartnerGeneralInfo :partner="partner" />
                <PartnerContactInfo
                    title="COMMERCIAL CONTACT INFO"
                    :contactName="partner.commercialContact"
                    :contactEmail="partner.commercialContactEmail"
                    :contactPhone="partner.commercialContactPhone"
                />
                <PartnerContactInfo
                    title="FINANCIAL CONTACT INFO"
                    :contactName="partner.financialContact"
                    :contactEmail="partner.financialContactEmail"
                    :contactPhone="partner.financialContactPhone"
                />
            </LineItem>
            <LineItem
                class="col-12 row no-gutters partner_row__notes" 
            >
                <div class="col-2 partner_row__notes__key">
                    Special Notes </div>
                <div class="col-10 partner_row__notes__value">
                    {{partner.specialNotes}} </div>
            </LineItem>
            <LineItem
                class="col-12 row no-gutters partner_row__edit_links" 
            >
                <div class="col-12">
                    <router-link :to="{name: 'sublocations', params: {partner:`${partner.id}`}}">View Sublocations</router-link>
                </div>
                <div
                    v-if="canEdit" 
                    class="col-12"
                >
                    <router-link :to="{name: 'partnerEdit', params: {partner:`${partner.id}`}}">Edit Partner</router-link>
                </div>
                <div
                    v-if="dev"
                    @click="$emit('deletePartner', partner.id)" 
                    class="col-12"
                >
                    <span class="delete">DELETE PARTNER</span>
                </div>
            </LineItem>
        </div>

    </div>
</template>

<script>
import LineItem from 'components/line_item.vue'
import PartnerGeneralInfo from 'components/partners/partner_general_info'
import PartnerContactInfo from 'components/partners/partner_contact_info'

import { mapGetters } from 'vuex'

export default {
    name: 'PartnerRow',
    components: {
        LineItem,
        PartnerGeneralInfo,
        PartnerContactInfo,
    },
    props: {
        partner: {
            type: Object,
            required: true,
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        
        }
    },
    computed: {
        activeHeight() {
            return '346px';
        },
        ...mapGetters([
            'dev'
        ]),
        ...mapGetters('PartnerStore', [
            'canEdit'
        ])
    },
    methods: {
        toggle() {
            this.active = !this.active
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.partner_row {
    &__line {
        background-color: #fff;
        cursor: pointer;
        padding-left: 30px;
    }

    &__action {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-top: 4px;
        margin-left: spacing(xs);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
    }

    &__sort {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    &__delete {
        background-image: url('~img/delete.svg');
        background-size: 15px;
    }

    &__head {
        display: flex;
        font-size: fz(sm);
    }

    &__icon {
        @include background-image('', 'partner_normal.svg', '../../img/');
        background-size: contain;
        width: spacing(sm);
        height: spacing(sm);
        margin-top: 2px;

        &--active {
            @include background-image('', 'partner_open.svg', '../../img/');
        }
    }

    &__title {
        margin-left: spacing(xs);
    }

    &__expanded {
        height: 346px !important;
        transition: max-height 100ms;
        background-color: #fff;
        overflow: hidden;

        &--inner {
            min-height: 10px;
        }

        &__info {
            padding-left: 30px !important;
        }
    }

    &__notes {
        padding-top: 30px;
        &__key {
            padding-left: 64px !important;
            color: #555555;	
            font-family: Roboto;	
            font-size: 14px;	
            font-weight: bold;	
            line-height: 16px;
        }

        &__value {
            padding-left: 80px !important;
        }
    }

    &__edit_links {
        padding-left: 64px !important;
        padding-top: 10px;
        a {
            color: $brand_color;
        }

        span {
            color: #FF0000;
        }
    }
    

    &--active {
        .partner_row__title {
            @include below(xs) {
                max-width: 115px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
</style>
