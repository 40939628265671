<template>
    <div class="partners_add_row__header">
        <line-item
        class="col partners_add_row__header-inner height-xs--8 align-items-center d-flex"
        :class="{'justify-content-center': center}">
            <div v-if="logo" class="partners_add_row__header-logo" :class="`partners_add_row__header-logo--${logo}`"></div>
            <div class="block partners_add_row__header-title">
                <slot>Explorer Header</slot>
            </div>
        </line-item>
    </div>
</template>

<script>
import lineItem from '../line_item.vue'

export default {
    name: 'PartnersAddRow',
    props: {
        logo: String,
        center: Boolean,
    },
    components: {
        lineItem,
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.partners_add_row__header {
    font-size: fz(sm);

    &-title {
        margin-left: 12px;
    }
    &-inner {
        padding-left: spacing(lg);
        color: $brand_color;
    }

    &-logo {
        width: spacing(sm);
        height: spacing(sm);
        background-size: contain;

        &--partner {
            @include background-image('', 'partner_add.svg', '../../img/');
        }
    }
}
</style>
