var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-4 general_info__container" }, [
    _c("div", { staticClass: "row no-gutters general_info__title" }, [
      _vm._v(" GENERAL INFO "),
    ]),
    _c("div", { staticClass: "row no-gutters general_info__row" }, [
      _c("div", { staticClass: "col-6 general_info__row__key" }, [
        _vm._v(" Partner Assignment "),
      ]),
      _c("div", { staticClass: "col-6 general_info__row__value" }, [
        _vm._v(" " + _vm._s(_vm.partner.partnerAssignment) + " "),
      ]),
    ]),
    _c("div", { staticClass: "row no-gutters general_info__row" }, [
      _c("div", { staticClass: "col-6 general_info__row__key" }, [
        _vm._v(" Partner Shortcode "),
      ]),
      _c("div", { staticClass: "col-6 general_info__row__value" }, [
        _vm._v(" " + _vm._s(_vm.partner.partnerShortCode) + " "),
      ]),
    ]),
    _c("div", { staticClass: "row no-gutters general_info__row" }, [
      _c("div", { staticClass: "col-6 general_info__row__key" }, [
        _vm._v(" Company Number "),
      ]),
      _c("div", { staticClass: "col-6 general_info__row__value" }, [
        _vm._v(" " + _vm._s(_vm.partner.companyNumber) + " "),
      ]),
    ]),
    _c("div", { staticClass: "row no-gutters general_info__row" }, [
      _c("div", { staticClass: "col-6 general_info__row__key" }, [
        _vm._v(" VAT # (optional) "),
      ]),
      _c("div", { staticClass: "col-6 general_info__row__value" }, [
        _vm._v(" " + _vm._s(_vm.partner.vat) + " "),
      ]),
    ]),
    _c("div", { staticClass: "row no-gutters general_info__row" }, [
      _c("div", { staticClass: "col-6 general_info__row__key" }, [
        _vm._v(" Legal Address "),
      ]),
      _c("div", { staticClass: "col-6 general_info__row__value" }, [
        _vm._v(" " + _vm._s(_vm.fullAddress) + " "),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }