var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col-3 contact_info__container" }, [
    _c("div", { staticClass: "row no-gutters contact_info__title" }, [
      _vm._v(" " + _vm._s(_vm.title) + " "),
    ]),
    _c("div", { staticClass: "row no-gutters contact_info__value name" }, [
      _vm._v(" " + _vm._s(_vm.contactName) + " "),
    ]),
    _c("div", { staticClass: "row no-gutters contact_info__value email" }, [
      _vm._v(" " + _vm._s(_vm.contactEmail) + " "),
    ]),
    _c("div", { staticClass: "row no-gutters contact_info__value phone" }, [
      _vm._v(" " + _vm._s(_vm.contactPhone) + " "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }