var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ContextTitle", {
        attrs: { passedActions: _vm.titleBarActions, title: "Partners" },
      }),
      _vm.canEdit
        ? _c(
            "PartnersAddRow",
            {
              attrs: { logo: "partner" },
              nativeOn: {
                click: function ($event) {
                  return _vm.createPartner.apply(null, arguments)
                },
              },
            },
            [_vm._v(" Add new partner ")]
          )
        : _vm._e(),
      _vm._l(_vm.partnerList, function (partner) {
        return [
          _c("PartnerRow", {
            key: partner.id,
            attrs: {
              partner: partner,
              active: _vm.activeRows.includes(partner.id),
            },
            on: { deletePartner: _vm.deletePartner },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }